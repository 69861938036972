import React, { useState, useEffect, useRef } from 'react'
import Header from './form/Header'
import FormPage from './form/FormPage'
import NextButton from './form/NextButtonComponent'
import { useDispatch, useSelector } from 'react-redux'
import { get, set,  reset } from '../reducers/data'
import WaitAndThanks from './form/WaitAndThanks'
import { Container } from 'react-bootstrap'

import formTemplate from '../utils/form'
import utils from '../utils/index.js'
import RisparmioFinaleComponent from './form/RisparmioFinaleMobileComponent'
import { useNavigate } from "react-router-dom";
import Thanks from './Thanks.jsx'

const testRendering = utils.testRendering

function FormEnel () {
  const navigate = useNavigate();
  const data = useSelector(get)
  const dispatch = useDispatch()
  const anchor = useRef(null)
  const main = useRef(null)
  let pageIndex = data["pageIndex"] || 0
  const setPageIndex = (index) => dispatch(set({ cell: "pageIndex", value: index }))
  // const [pageIndex, setPageIndex] = useState(0)
  let screen = data["screen"] || 1
  const setScreen = (index) => { dispatch(set({ cell: "screen", value: index }))
  setTimeout(() => {
    anchor?.current?.focus()
    }, 100)}

  // const [screen, setScreen] = useState(1)
  const [isFinished, setIsFinished] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const history = unstable_HistoryRouter();

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
        window.history.go(1);
        if (screen > 1) return changeScreen(screen - 1)
        else if (pageIndex > 0) changePage(pageIndex - 1, true)
        else navigate("/")
    };

    window.enel_data = {
      page: {
          pageInfo: {
              pageName: "Dati abitazione",
              pageURL: "https://simulatore-risparmio-energia.enelxstore.com/form#page=1"
          }
      }
  };
  window?._satellite?.track("pageview")
  })


  const shouldRender = item => {
    if (!item.conditionalRendering) return true
    else return testRendering(item, data)
  }

  const copyToRedux = (data, res) => {
    const skip = ["C24", "C19","C18", "C28", "C29", "C34"]
    for (let value in res) {
      if (skip.includes(value)) continue;
      dispatch(
        set({
          cell: value,
          value: res[value]
        })
      )
    }
  }

  const clearInterventi = () => {
    ["C41","C45", "C48", "C53"].forEach(x => dispatch(set({ cell: x, value: false })));
  }

  if (pageIndex === -1)
  return <Thanks error={`Sfortunamente l'indirizzo: <br /> <b> ${data["C14"]} ${data["CAP"]} </b> <br /> non è coperto dai servizi di elettrificazione.`} 
    retry={true}
    main={main}
    enelstore={false} />
  if (pageIndex === 100) return <RisparmioFinaleComponent main={main} />

  const changePage = (index, back) => {
    if (back === true) {
      const prevPage = formTemplate.pages[pageIndex -1]
      let items = prevPage.items.filter(x => shouldRender(x, data))
      if (items.length > 0) {
        const max = Math.max(...items.map(x => x.desktopPage))
        setScreen(max)
      }
    }
    setPageIndex(index)

    window.enel_data = { page: { pageInfo: { } }};
    window.enel_data.page.pageInfo.pageName = formTemplate.pages[index]?.title;
    window.enel_data.page.pageInfo.pageURL = "https://simulatore-risparmio-energia.enelxstore.com/form#page=" + (index + 1);
    window._satellite.track("pageview")
    window.scrollTo(0, 0)
    dispatch(set({
      cell:"pageIndex",
      value: index
    }))
  }
  
  const changePageClick = async () => {
    if (page.onSubmit) {
        setIsFinished(true);
        setIsLoading(true);
        const res = await page.onSubmit(data);
              
        if (res.products) {
            dispatch(set({
              cell: "products",
              value: res.products
            }))
    
            copyToRedux(data, {
              ...res.data
            })
            clearInterventi();
            // debugger
            setIsLoading(false);
            // setTimeout(() => {
                setIsFinished(false);
                changePage(100);
                // setPageIndex(100);
            // }, 3000)
          }
          else {
            copyToRedux(data, res)
            clearInterventi();
            setIsLoading(false);
            // setTimeout(() => {
                setIsFinished(false);
                changePage(pageIndex + 1);
                // setPageIndex(pageIndex + 1);
                setScreen(1);
            // }, 1600)
          }
       
    }
    else {
      if (page.onCheck){
        const res = page.onCheck(data)

        if (res === true){
          window.location.href = "https://www.enelxstore.com/it/it/simulatore-impianto-fotovoltaico?ecid=Referral-SimulatoreElettrificazione-null-null-null"
          return;
        }
      }
        
        setScreen(1)
        changePage(pageIndex +1)
        // setPageIndex(pageIndex + 1)
    }
  }

const changeScreen = async (index) => {
  if (index === 2 && page.items.find(x => x.type === "address")){
    const CAP_result = await utils.checkCAP(data["CAP"])
    if (CAP_result === false){
      setPageIndex(-1)
      return;
    }
  }

    let items = page.items.filter(x => x.desktopPage === index && shouldRender(x))
    while(items.length === 0 || index > 10) {
        index > screen ? index++ : index--
        //debugger
        if (index >= lastScreen)
            return changePageClick()
        else
            items = page.items.filter(x => x.desktopPage === index && shouldRender(x))
    }
    setScreen(index)
}

const page = formTemplate.pages.filter(p => shouldRender(p))[pageIndex || 0]
if (page === undefined || page.items === undefined)  
  return <RisparmioFinaleComponent main={main} />

const array = page.items.map(x => x.desktopPage).filter(x => x !== undefined && !isNaN(x))
const lastScreen = Math.max(...array)

  const buttonEnabled = page.items.filter(x =>x.desktopPage === screen).reduce((prev, curr) => {
    if (prev === false) return false
    if (curr.cell === "D48") return true
    if (curr.type === "label" || curr.type === "spacer") return true
    if (curr.cell === "D48_val" && data["C48"] === false) return true
    if (curr.cell === "D84" && data["C84"] !== true) return true

    if (!shouldRender(curr)) return true
    if (curr.type ==="percentage"){
        // No = Non la conosco
        if (data["C31"] === "No") return true
        else {
            const riscAmbiente = data['C25']
            const riscAcqua = data['C26']
            const pianoCottura = data['C27']
          
            const fieldsTotal = [
              {
                name: 'Elettrico',
                cell: 'C33'
              },
              {
                name: riscAmbiente,
                cell: 'F25'
              },
              {
                name: riscAcqua,
                cell: 'F26'
              },
              {
                name: pianoCottura,
                cell: 'F27'
              }
            ]
            const fields = []
            for (let field of fieldsTotal) {
              if (!field.name) continue
              if (!fields.map(x => x.name).includes(field.name)) fields.push(field)
            }

            for (let field of fields) {
              if (!data[field.cell] || data[field.cell] < 0) return false
            }
            return true;
        }
    }

    
    if (curr.type === "slider" && curr.cellSiNo){
      if (data[curr.cellSiNo] === "No" || data[curr.cell]) return true
    }
    if ((curr.type === "slider" || curr.type === "stepper") && curr.max){
      if (data[curr.cell] === undefined || data[curr.cell] > curr.max) return false
    }
    if ((curr.type === "slider" || curr.type === "stepper") && curr.min){
      if (data[curr.cell] === undefined || data[curr.cell] < curr.min) return false
    }


    if (curr.type === "multichoice"){
      let choices = curr.choices.filter(x => shouldRender(x))
      if (choices.length === 0) return true
      return choices.filter(x => data[x.cell] === true || data[x.cell] === "Si").length > 0
    }

    if (curr.type === "riscaldamento") {
      // if (data["C42"] === undefined || data["C42"] === null) return false
      return true;
    }

    if (curr.type === "address" && data["C14"] && data["CAP"]) return true

    if (curr.required !== false && (data[curr.cell] === "" || data[curr.cell] === undefined)) {
        return false
    }


    return true;
}, true)


  if (page.title === "Fine") return <RisparmioFinaleComponent />

  if (isFinished) return <WaitAndThanks main={main} loading={isLoading} />

  // console.log(page.title, screen)
  // if (page.title === "Configura la tua soluzione" && screen === 1){
  //   const showRiscaldamento = data["C24"] !== true && data["C25"] !== "Elettrico"
  //   if (!showRiscaldamento){
  //     if (page.onCheck(data))
  //     {
  //       window.location.href = "https://www.enelxstore.com/it/it/simulatore-impianto-fotovoltaico?ecid=Referral-SimulatoreElettrificazione-null-null-null"
  //     } 
  //     else {
  //       changeScreen(2)
  //       dispatch(set({
  //         cell: "C53",
  //         value: true
  //       }))  
  //     }
  //   }
  // }

  return (
    <div className='d-lg-none'>
      <Header
        title={isLoading ? "Stiamo elaborando la tua simulazione" : page.title}
        anchor={anchor}
        main={main}
        subtitle={page.subtitle}
        hideSubtitleMobile={page.hideSubtitleMobile}
        page={page.page ?? pageIndex}
      />
      <Container fluid as="main" id="main" tabIndex={0} ref={main} >
        <FormPage 
        riepilogo={page.items.filter(x => x.desktopPage === screen)[0]?.riepilogo !== false}
        items={page.items.filter(x => x.desktopPage === screen)} />

        <NextButton
          buttonEnabled={!buttonEnabled}
          onlyContinue={page.showBack}
          onBack={screen > 1 ? 
            () => changeScreen(screen - 1) :
                pageIndex > 0 ? () => 
                changePage(pageIndex - 1, true)
             : () => { navigate("/")} }
          text={page.nextText ? page.nextText : 'AVANTI'}
          onClick={() => changeScreen(screen + 1)}
        />
      </Container>
    </div>
  )
}

export default FormEnel
